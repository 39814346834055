import {Component} from '@angular/core';
import {TranslationService} from "../../../core/services/translation.service";

@Component({
  selector: 'app-sub-change-without-login-after',
  templateUrl: './sub-change-without-login-after.component.html',
  styleUrls: ['./sub-change-without-login-after.component.css'],
  standalone: true,
  imports: [  ],
})
export class SubChangeWithoutLoginAfterComponent {

  constructor(public translationService: TranslationService) {
  }
}

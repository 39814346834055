import {Component, Input, ViewChild} from '@angular/core';
import {LoginComponent} from '../../core/components/login/login.component';
import {TranslationService} from '../../core/services/translation.service';
import {ConfigService} from "../../core/services/config.service";
import {LoginService} from "../../core/components/login/login.service";
import {CssPropertiesEnum} from "../../core/models/css-properties.enum";
import {RedirectService} from "../../data/services/redirect.service";
import {ActivatedRoute} from "@angular/router";
import {LandingPage} from "../../data/models/landing-page.model";
import {LandingPageService} from "../../data/services/landing-page.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @ViewChild('login') loginComponent: LoginComponent;
  isAuthenticated: boolean = false;
  CssPropertiesEnum = CssPropertiesEnum;
  activeRoute:string = null;
  @Input() horizontalNavigation: boolean = true;

  links: LandingPage[];
  filteredLinks: LandingPage[];
  public isCollapsed = true;

  constructor(
    public translationService: TranslationService,
    private configService: ConfigService,
    private loginService: LoginService,
    public redirectService: RedirectService,
    private route:ActivatedRoute,
    public landingPageService: LandingPageService
  ) {
    this.loginService.getAuthData().subscribe((authData) => {
      this.isAuthenticated = !!authData;
      this.buildLinkList();
    });

    this.route.url.subscribe(url => {
      this.activeRoute = '';
    });

    this.landingPageService.getLandingPages().subscribe(pages => {
      if(pages && pages.length > 0) {
        this.links = pages;
        this.buildLinkList();
      }
    });
  }

  buildLinkList() {
    const newLinkList = [];
    if(this.links) {
      this.links.forEach(link => {
        if (link.showBeforeLogin && !this.isAuthenticated) {
          newLinkList.push(link);
        } else if (link.showAfterLogin && this.isAuthenticated) {
          newLinkList.push(link);
        }
      });
    }
    this.filteredLinks = newLinkList;
  }

  onClickEvent(): void{
    this.loginComponent.onClickEvent();
  }

  navigateToExternRoute(link: LandingPage) {
    this.redirectService.redirect(link.externLink, true);
  }
}

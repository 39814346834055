import {Component} from '@angular/core';
import {TranslationService} from "../../services/translation.service";

@Component({
  selector: 'app-child-user-information',
  templateUrl: './child-user-information.component.html',
  styleUrls: ['./child-user-information.component.css']
})
export class ChildUserInformationComponent {
  constructor(
    public translationService: TranslationService,
  ) {
  }
}

import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {TranslationService} from "../../../core/services/translation.service";
import {VHostService} from "../../../core/services/v-host.service";
import {CustomerService} from "../../../data/services/customer.service";
import {InvoiceInfo} from "../../../data/models/invoiceInfo.model";
import {PageEvent} from "@angular/material/paginator";
import {CssPropertiesEnum} from "../../../core/models/css-properties.enum";
import {isPlatformBrowser} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.css']
})
export class CustomerInvoiceComponent {

  CssPropertiesEnum = CssPropertiesEnum;
  totalAmount : number;
  pageSize : number = 2;
  currentInvoices : InvoiceInfo[] = [];
  isLoading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    public translationService: TranslationService,
    public vHostService: VHostService,
    public customerService: CustomerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.paramMap.subscribe((queryParams) => {
      this.pageSize = Number(queryParams.get("pageSize"));
    });
    customerService.getTotalNumberOfInvoices().subscribe( ta => {
      this.totalAmount = ta;
      if(isPlatformBrowser(this.platformId)) {
        this.loadInvoices();
      }
    });
  }

  loadInvoices(event : PageEvent = null) {
    this.isLoading = true;
    this.customerService.getInvoiceInfos(event? event.pageIndex : 0, event? event.pageSize : this.pageSize, null, null).subscribe(invoices => {
      this.currentInvoices = invoices;
      this.isLoading = false;
    });
  }
}

import {Directive, Inject, Input, Optional} from "@angular/core";
import {NgControl} from "@angular/forms";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {CustomDateFormat, DateParse} from "../models/custom-date-format";

@Directive({
  selector: '[datePickerFormat]',
})
export class DatePickerFormatDirective {
  @Input() public configDateParse: DateParse;

  @Input("datePickerFormat")
  set datePickerFormat(format: string) {
    if (this.configDateParse) {
      this.matDateFormat?.updateDateFormat(
        this.configDateParse,
      );
    } else {
      this.matDateFormat?.updateDateFormat({ dateInput: format });
    }

    const value = this.ngControl.value;
    this.ngControl.valueAccessor?.writeValue(value);
  }

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: CustomDateFormat,
    @Optional() private ngControl: NgControl
  ) {}
}

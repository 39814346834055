<div class="bonusDialog">
  <h2
    mat-dialog-title>{{ bonus.name }}{{ (showBonusPoints) ? ' - ' + bonus.bonusValue + ' ' + translationService.getTranslation('order_bonusPoints') : '' }}</h2>
  <mat-dialog-content class="mat-typography">
    <img [alt]="bonus.description" *ngIf="bonus.hasImage" [src]="bonus.img" class="bonusImg" fitImage>
    <p *ngIf="!bonus.descriptionFromDocument" [innerHTML]="bonus.description"></p>
    <p *ngIf="bonus.descriptionFromDocument" [innerHTML]="bonus.descriptionFromDocument"></p>

    <p *ngIf="bonus.userAdjustment > 0" [innerHTML]="translationService.getTranslation('order_adjustment') + ' ' + bonus.userAdjustment + ' ' + bonus.isoCurrencyCode">
      <br/>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" mat-dialog-close="" data-cy="btn-bonus-details.close"
            [innerHTML]="translationService.getTranslation('button_close')"></button>
  </mat-dialog-actions>
</div>

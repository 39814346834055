<div id="loginBox">
  <button class="close" (click)="onClose()">&times;</button>
  <h4 mat-dialog-title [innerHTML]="translationService.getTranslation('login_box_header')"></h4>
  <ng-container *ngIf="!ssoConfiguration.usage.toUpperCase().startsWith('OAUTHHASSO')">
    <mat-dialog-content class="mat-typography" [formGroup]="loginForm" (keyup.enter)="onLogin()">
      <div *ngIf="loading" style="text-align: center; margin:5px">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <ng-container *ngIf="!loading">
        <div class="row">
          <div class="col alert alert-danger" *ngIf="error">
            {{ error }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label [innerHTML]="translationService.getTranslation('login_email')"></mat-label>
              <input matInput formControlName="email" type="text" required email name="username" autocomplete="off">
              <mat-error [innerHTML]="loginForm.get('email').getError('message')"></mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label [innerHTML]="translationService.getTranslation('login_password')"></mat-label>
              <input matInput formControlName="password" type="password" required name="password">
              <mat-error>{{loginForm.get('password').getError('message')}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="me-3" mat-raised-button color="primary" [disabled]="loading" (click)="onLogin()" [innerHTML]="translationService.getTranslation('login_login')"></button>
      <button mat-raised-button color="primary" mat-dialog-close [innerHTML]="translationService.getTranslation('login_close')"></button>
    </mat-dialog-actions>
  </ng-container>
  <mat-dialog-content *ngIf="ssoConfiguration.usage.toUpperCase().startsWith('OAUTHHASSO')" class="mat-typography">
    <iframe title="" id="loginBoxIFrame" [src]="hassoLoginBoxSrc()" style="border: none;"></iframe>
  </mat-dialog-content>
</div>

<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('sub_change_intro')"></h4>
  </div>
    <div class="col-12 mb-3" [innerHTML]="identified? translationService.getTranslation('sub_change_description') : translationService.getTranslation('unauthorized_provideInfo_description')">
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="col-12" *ngIf="!identified">
      <button mat-flat-button
              class="btn btn-secondary"
              (click)="navigateToIdentification()"
              [innerHTML]="translationService.getTranslation('button_toIdentificationForm')"></button>
    </div>
    <div *ngIf="isLoadingSub" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div class="mt-3" *ngIf="identified && !isLoadingSub && subs.length == 0" [innerHTML]="translationService.getTranslation('intro_noSubs')"></div>
    <ng-container *ngIf="!isLoadingSub && subs.length > 0">
      <mat-card appearance="outlined" *ngFor="let sub of subs" class="mt-3">
        <div class="row m-3">
          <div class="col-12 col-md-6">
            <mat-card-title>{{sub.productDescription}}</mat-card-title>
            <mat-card-subtitle *ngIf="sub.subId" [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.subId">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.variantDescription" [innerHTML]="translationService.getTranslation('order_edition') + ': ' + sub.variantDescription">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.validDate" [innerHTML]="translationService.getTranslation('period') + ': ' +
              (sub.validDate.validFrom | date: translationService.getTranslation('dateFormat')) + ' - ' +
              ((sub.validDate.validUntil) ? (sub.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : translationService.getTranslation('subscription_endless'))">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.curDelivery">
              {{sub.curDelivery.person.addressCodeDescription}}
              {{sub.curDelivery.person.firstname}}
              {{sub.curDelivery.person.lastname}},
              {{sub.curDelivery.address.city}},
              {{sub.curDelivery.address.street}} {{sub.curDelivery.address.houseno | houseNoPipe}}
            </mat-card-subtitle>
          </div>
          <div class="col-12 col-md-6">
            <mat-card-actions class="card-actions">
              <button mat-raised-button color="primary" (click)="onNewSubChange(sub)" [innerHTML]="translationService.getTranslation('button_new_sub_change')"></button>
            </mat-card-actions>
          </div>
        </div>

        <mat-card-content *ngIf="sub && sub.subChanges && sub.subChanges.length > 0">
          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let subChange of sub.subChanges; let innerI = index">
              <mat-expansion-panel-header>
                <mat-panel-title [innerHTML]="translationService.getTranslation('label_' + subChange.changeType.toString() + '_description')"></mat-panel-title>
                <mat-panel-description>
                  {{subChange.validDate.validFrom | date: translationService.getTranslation('dateFormat')}}
                  {{(subChange.validDate.validUntil && subChange.validDate.validUntil != subChange.validDate.validFrom) ?
                  ' - ' + (subChange.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : ''
                  }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>

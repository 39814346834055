import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-general-warning',
  templateUrl: './general-warning.component.html',
  styleUrls: ['./general-warning.component.css']
})
export class GeneralWarningComponent {
  @Input() warnings: any[] = [];

  constructor() {
  }
}

export enum CssPropertiesEnum {
  // Hintergrund der Button
  buttonBackgroundColor = 'css.buttonBackgroundColor',
  // Textfarbe der Button
  buttonColor = 'css.buttonColor',
  // Höhe des Standardbuttons
  buttonHeight = "css.buttonHeight",
  // Hintergrund der Offer Button
  offerButtonBackgroundColor = 'css.offer.buttonBackgroundColor',
  // Textfarbe der Offer Button
  offerButtonColor = 'css.offer.buttonColor',
  // Hintergrund der Offer Header Beschreibung
  offerHeaderBackground = 'css.offer.headerBackgroundColor',
  // Textfarbe der Offer Header Beschreibung
  offerHeaderColor = 'css.offer.headerColor',
  // Hintergrund der HighlightOffer Header Beschreibung
  offerHighlightHeaderBackground = 'css.offer.highlightHeaderBackgroundColor',
  // Textfarbe der HighlightOffer Header Beschreibung
  offerHighlightHeaderColor = 'css.offer.highlightHeaderColor',
  // Textausrichtung der Überschrift für Highlights
  offerHighlightHeaderTextAlign = 'css.offer.highlightHeaderTextAlign',
  // Textfarbe der HighlightOffer Header Beschreibung
  offerFooterBackground = 'css.offer.footerBackgroundColor',
  // Textfarbe der Offer Footer Beschreibung
  offerFooterColor = 'css.offer.footerColor',
  // Angabe der Spalten in %
  offerFxFlex = 'css.offer.fxFlex',
  // Angabe der Spalten in % -> screen and (max-width: 599px)
  // Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px)
  offerFxFlexSM = 'css.offer.fxFlexSM',
  // Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px)
  offerFxFlexMD = 'css.offer.fxFlexMD',
  // Angabe der Spalten in % -> screen and (min-width: 1280px) and (max-width: 1919px)
  offerFxFlexLG = 'css.offer.fxFlexLG',
  // Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px)
  offerFxFlexXL = 'css.offer.fxFlexXL',
  offerFxFlexXXL = 'css.offer.fxFlexXXL',
  // Angabe der Spalten in %
  landingFxFlex = 'css.landing.fxFlex',
  // Angabe der Spalten in % -> screen and (max-width: 599px)
  landingFxFlexXXL = 'css.landing.fxFlexXXL',
  // Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px)
  landingFxFlexSM = 'css.landing.fxFlexSM',
  // Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px)
  landingFxFlexMD = 'css.landing.fxFlexMD',
  // Angabe der Spalten in % ->  screen and (min-width: 1280px) and (max-width: 1919px)
  landingFxFlexLG = 'css.landing.fxFlexLG',
  // Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px)
  landingFxFlexXL = 'css.landing.fxFlexXL',
  // Navigation Hintergrundfarbe
  naviBackgroundColor = 'css.navi.backgroundColor',
  // Navigation Text
  naviColor = 'css.navi.color',
  // Navigation Textdecoration
  naviTextDecoration = 'css.navi.textdecoration',
  // Navigation Hover Hintergrundfarbe
  naviHoverBackgroundColor = 'css.navi.hoverBackgroundColor',
  // Navigation Hover Text
  naviHoverColor = 'css.navi.hoverColor',
  // Navigation Hover Textdecoration
  naviHoverTextdecoration = 'css.navi.hoverTextdecoration',
  // Navigation Active Hintergrundfarbe
  naviActiveBackgroundColor = 'css.navi.activeBackgroundColor',
  // Navigation Active Text
  naviActiveColor = 'css.navi.activeColor',
  // Navigation Active Textdecoration
  naviActiveTextdecoration = 'css.navi.activeTextdecoration',
  // Navigation Border Top
  naviBorderTop = 'css.navi.borderTop',
  // Navigation Border Right
  naviBorderRight = 'css.navi.borderRight',
  // Navigation Border Bottom
  naviBorderBottom = 'css.navi.borderBottom',
  // Navigation Border Left
  naviBorderLeft = 'css.navi.borderLeft',
  // Navigation Border Top
  naviHoverBorderTop = 'css.navi.hoverBorderTop',
  // Navigation Border Right
  naviHoverBorderRight = 'css.navi.hoverBorderRight',
  // Navigation Border Bottom
  naviHoverBorderBottom = 'css.navi.hoverBorderBottom',
  // Navigation Border Left
  naviHoverBorderLeft = 'css.navi.hoverBorderLeft',
  // Navigation Border Top
  naviActiveBorderTop = 'css.navi.activeBorderTop',
  // Navigation Border Right
  naviActiveBorderRight = 'css.navi.activeBorderRight',
  // Navigation Border Bottom
  naviActiveBorderBottom = 'css.navi.activeBorderBottom',
  // Navigation Active Border Left
  naviActiveBorderLeft = 'css.navi.activeBorderLeft',
  // CSS-Klassen die dem Bodytag hinzugefügt werden sollen
  bodyClasses = 'css.body.classes',
  // CSS-Klassen die der Offer-Überschrift hinzugefügt werden sollen
  offerHeaderClasses = 'css.offer.headerClasses',
  // Angabe der Spalten in %
  bonusFxFlex = 'css.bonus.fxFlex',
  // Angabe der Spalten in % -> screen and (max-width: 599px)
  bonusFxFlexXXL = 'css.bonus.fxFlexXXL',
  // Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px)
  bonusFxFlexSM = 'css.bonus.fxFlexSM',
  // Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px)
  bonusFxFlexMD = 'css.bonus.fxFlexMD',
  // Angabe der Spalten in % -> screen and (min-width: 1280px) and (max-width: 1919px)
  bonusFxFlexLG = 'css.bonus.fxFlexLG',
  // Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px)
  bonusFxFlexXL = 'css.bonus.fxFlexXL',
  // Angabe der Spalten in %
  customerFxFlex = 'css.customer.fxFlex',
  // Angabe der Spalten in % -> screen and (max-width: 599px)
  customerFxFlexXXL = 'css.customer.fxFlexXXL',
  // Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px)
  customerFxFlexSM = 'css.customer.fxFlexSM',
  // Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px)
  customerFxFlexMD = 'css.customer.fxFlexMD',
  // Angabe der Spalten in % ->  screen and (min-width: 1280px) and (max-width: 1919px)
  customerFxFlexLG = 'css.customer.fxFlexLG',
  // Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px)
  customerFxFlexXL = 'css.customer.fxFlexXL',
  // Schriftart
  hupFontFamily = 'css.fontFamily',
  // Text-Ausrichtung beim Slider (left, center, right)
  sliderTextAlign = 'slider_external_offer_data_position',
  formOfPaymentFxFlex = 'css.formOfPayment.fxFlex',
  // Angabe der Spalten in % -> screen and (min-width: 600px) and (max-width: 959px)
  formOfPaymentFxFlexSM = 'css.formOfPayment.fxFlexSM',
  // Angabe der Spalten in % -> screen and (min-width: 960px) and (max-width: 1279px)
  formOfPaymentFxFlexMD = 'css.formOfPayment.fxFlexMD',
  // Angabe der Spalten in % -> screen and (min-width: 1280px) and (max-width: 1919px)
  formOfPaymentFxFlexLG = 'css.formOfPayment.fxFlexLG',
  // Angabe der Spalten in % -> screen and (min-width: 1920px) and (max-width: 5000px)
  formOfPaymentFxFlexXL = 'css.formOfPayment.fxFlexXL',
  formOfPaymentFxFlexXXL = 'css.formOfPayment.fxFlexXXL',
  checkBoxBackgroundColor = 'css.checkbox.backgroundColor',
  checkBoxBorderColor = 'css.checkbox.borderColor',
  checkBoxCheckmarkColor = 'css.checkbox.checkmarkColor',
  buttonSecondaryColor = 'css.buttonSecondaryColor',
  buttonSecondaryBackgroundColor = 'css.buttonSecondaryBackgroundColor',
}

import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../../data/models/address.model';
import {Person} from '../../../data/models/person.model';
import {TranslationService} from '../../services/translation.service';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.scss']
})
export class AddressDetailComponent implements OnInit {
  @Input() address: Address;
  @Input() person: Person;

  constructor(public translationService: TranslationService) {
  }

  ngOnInit(): void {
  }

}

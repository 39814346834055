
<h2 [innerHTML]="translationService.getTranslation('login_box_header')"></h2>

<div *ngIf="loading" style="text-align: center; margin:5px">
  <app-loading-spinner></app-loading-spinner>
</div>

<ng-container *ngIf="useHassoForm && hassoLoginUrl">
  <iframe title="" [src]="hassoLoginUrl" [style.height]="ssoFormPropagatedHeight"></iframe>
  <div *ngIf="!loading" class="btn-back">
    <button mat-raised-button color="primary" (click)="onBack()" [innerHTML]="translationService.getTranslation('button_back')"></button>
  </div>
</ng-container>

<ng-container *ngIf="!useHassoForm">
  <div class="mat-typography" [formGroup]="loginForm" (keyup.enter)="onLogin()">
    <ng-container *ngIf="!loading">
      <div class="row">
        <div class="col-12 alert alert-danger" *ngIf="error" [innerHTML]="translationService.getTranslation(error)">
        </div>
        <div class="col-12">
          <mat-form-field>
            <mat-label [innerHTML]="translationService.getTranslation('login_email')"></mat-label>
            <input matInput formControlName="email" type="text" required email name="username" autocomplete="off">
            <mat-error>{{loginForm.get('email').getError('message')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field>
            <mat-label [innerHTML]="translationService.getTranslation('login_password')"></mat-label>
            <input matInput formControlName="password" type="password" required name="password">
            <mat-error>{{loginForm.get('password').getError('message')}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </div>
  <div>
    <button class="me-3" mat-raised-button color="primary" [disabled]="loading" (click)="onLogin()" [innerHTML]="translationService.getTranslation('login_login')"></button>
    <button mat-raised-button color="primary" [innerHTML]="translationService.getTranslation('login_close')"></button>
  </div>
</ng-container>

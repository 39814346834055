import {Component} from '@angular/core';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {PaymentProvider, PaymentTransactionModel} from './paymentTransaction.model';
import {ICreateOrderRequest, IPayPalConfig} from "ngx-paypal";
import {PaymentProviderService} from "../../services/payment-provider.service";
import {ConfigService} from "../../services/config.service";
import {RedirectService} from "../../../data/services/redirect.service";
import {WebSocketService} from "../../services/web-socket.service";
import {TranslationService} from "../../services/translation.service";

declare var BuckarooSdk: any;


@Component({
  selector: 'app-onlinepayment',
  templateUrl: './onlinepayment.component.html',
  styleUrls: ['./onlinepayment.component.scss']
})
export class OnlinepaymentComponent {

  buckarooTransactionId: string = null;
  buckarooProcessingSuccessful: boolean = true;
  isBuckaroo = false;
  isPaypal = false;
  transaction: PaymentTransactionModel = null;

  payPalClientId: string;
  payPalIsoCurrencyCode: string;

  public payPalConfig ?: IPayPalConfig;

  constructor(
    private hupSubscriptionsService: HupSubscriptionsService,
    private paymentTransactionService: PaymentProviderService,
    private configService: ConfigService,
    private redirectService: RedirectService,
    private wssService: WebSocketService,
    public translationService: TranslationService
  ) {
    this.paymentTransactionService.getPaymentProviderResultData()?.subscribe(transaction => {
      if (transaction && transaction.amount > 0 && transaction.invoiceText && (transaction.transactionId === '' || !transaction.transactionId)) {
        if (transaction.provider === PaymentProvider.BUCKAROO) {
          this.initBuckaroo(transaction);
        } else if (transaction.provider === PaymentProvider.PAYPAL) {
          this.initPaypal(transaction);
        }
      }
    });

    this.payPalClientId = this.configService.getConfig('paymentprovider.paypal.clientid')?.value;
    this.payPalIsoCurrencyCode = this.configService.getConfig('paymentprovider.paypal.isoCurrency')?.value;
  }

  private initBuckaroo(transaction: PaymentTransactionModel):  void {
    this.isBuckaroo = true;
    transaction.wssId = this.wssService.getWssId();
    this.hupSubscriptionsService.startBuckarooPaymentTransaction(transaction).subscribe(result => {
      if (result && result.transactionId) {
        this.buckarooProcessingSuccessful = true;
        this.buckarooTransactionId = result.transactionId;
        transaction.redirectUrl = result.redirectUrl;
        transaction.transactionId = result.transactionId;
        this.transaction = transaction;
      }
    });
  }

  initPaypal(transaction: PaymentTransactionModel): void {
    this.isPaypal = true;
    this.payPalConfig = {
      currency: this.payPalIsoCurrencyCode,
      clientId: this.payPalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.payPalIsoCurrencyCode,
            value: transaction.amount + '',
          },
          description: transaction.invoiceText
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
          transaction.transactionId = data.orderID;
          this.paymentTransactionService.setNewTransaction(transaction)
        });

      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        //this.showCancel = true;

      },
      onError: err => {
        console.log('OnError', err);
        //this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        //this.resetStatus();
      }
    };
  }

  goToBuckaroo(): void {
    this.redirectService.redirect(this.transaction.redirectUrl, true, false);
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MessageSearchResult} from '../models/message-search-result';
import {catchError} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogisticServiceService {

  constructor(private http: HttpClient) {
  }

  getDelayMessages(district: string): Observable<MessageSearchResult[]> {
    return this.http.get<MessageSearchResult[]>('/webabo/logisticMessages/delay/' + district).pipe(catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );


  }
}

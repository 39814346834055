import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  // verarbeitet Fehler bei API Anfragen und setzt sie ggf. an übergebene Formularfelder
  handleAPIError(formGroups: FormGroup[], errors, unhandledErrors: string[], stepper?: MatStepper): string[] {
    if (typeof errors === 'string') {
      unhandledErrors.push(errors);
      return unhandledErrors;
    }
    errorLoop:
    for (const [groupKey, group] of Object.entries(errors)) {
      if (typeof group === 'object') { // -> rekursiv?
        // Wir haben eine unterGruppe -> weiter suchen
        errorLoopGroup:
        for (const [field, message] of Object.entries(group)) {
          for (const formGroup of formGroups) {
            try {
              formGroup.setErrors({message});
              formGroup.get(groupKey).get(field).setErrors({message});
              formGroup.get(groupKey).get(field).markAsTouched();
              // Feld gefunden, weiter mit nächstem Fehler
              continue errorLoopGroup;
            } catch (e) {}
          }
          // Feld nicht gefunden, ab in die general Errors damit
          unhandledErrors.push(message);
        }
      }
      else {
        // Keine Untergruppe, oder Fehler an der Gruppe direkt
        // for (const formGroup of formGroups) {
        //   try {
        //     formGroup.setErrors({message: group});
        //     formGroup.get(groupKey).setErrors({message: group});
        //     formGroup.get(groupKey).markAllAsTouched();
        //     // Feld gefunden, weiter mit nächstem Fehler
        //     continue errorLoop;
        //   } catch (e) {
        //   }
        // }
        // Feld nicht gefunden, ab in die general Errors damit
        unhandledErrors.push(String(group));
      }
    }

    // Ersten fehlerhaften Step ermitteln und aktiv schalten
    if (stepper) {
      let index = 0;
      for (const step of stepper.steps) {
        if (step.hasError) {
          stepper.selectedIndex = index;
          break;
        }
        index++;
      }
    }

    return;
  }
}

import {Component} from '@angular/core';
import {TranslationService} from "../../core/services/translation.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IdentificationService} from "../../core/services/identification.service";
import {IdentificationData} from "../../data/models/identification-data.model";
import {Person} from "../../data/models/person.model";
import {Address} from "../../data/models/address.model";
import {LoginService} from "../../core/components/login/login.service";
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";
import {CustomValidators} from "../../core/validators";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../core/services/config.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.css']
})
export class IdentificationComponent{

  isLoading: boolean = false;
  errorsArr: string[] = [];
  formGroup: FormGroup;
  showIBAN: boolean = false;
  showPhone: boolean = false;
  showBirthday: boolean = false;
  showEmail: boolean = false;
  showClientNo: boolean = false;
  identificationData: IdentificationData = null;
  notFoundAddress: boolean = true;
  notFoundExtendedData: boolean = true;
  searched: boolean = false;
  maskedOverviewText: string;
  returnPath: NavigationCommand;
  registeringAgreed: boolean = false;
  constructor(
    public translationService: TranslationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public identificationService: IdentificationService,
    private loginService: LoginService,
    private navigationService: NavigationService,
    private http: HttpClient,
    private configService: ConfigService) {

    this.maskedOverviewText = '';
    this.formGroup = this.formBuilder.group({
      searchAddress: this.formBuilder.group({
        showAddress: [''],
        address: this.formBuilder.group({
          country: [''],
          zipcode: [''],
          city: [''],
          street: [''],
          houseNo: [''],
          extraLine: [''],
          houseNoExt: [''],
          stiege: [''],
          stock: [''],
          tuer: [''],
          district: [''],
        }),
        person: this.formBuilder.group({
          title: [''],
          salutation: [''],
          firstName: [''],
          lastName: [''],
          initials: [''],
          middlename: [''],
        }),
        extendedValues: this.formBuilder.group( {
          email: ['',[CustomValidators.checkIdentificationFieldIsCorrect( 'EMAIL', () => this.identificationData)]],
          phone: ['',[CustomValidators.checkIdentificationFieldIsCorrect( 'PHONE', () => this.identificationData)]],
          iban: ['',[CustomValidators.checkIdentificationFieldIsCorrect( 'IBAN', () => this.identificationData)]],
          birthday: ['',[CustomValidators.checkIdentificationFieldIsCorrect( 'BIRTHDAY', () => this.identificationData)]],
          clientNo: ['',[CustomValidators.checkIdentificationFieldIsCorrect( 'CLIENTNO', () => this.identificationData)]],
          copyEmail: [false],
          registerEmail: ['', [Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|.(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]]
        })
      })
    });

    this.route.paramMap.subscribe((params) => {
      const returnPath = params.get('returnPath');
      if(returnPath) {
        this.returnPath = returnPath as NavigationCommand;
      }
    });

    this.formGroup.get('searchAddress.address').valueChanges.subscribe( () => {
      this.resetVariables();
    });

    this.formGroup.get('searchAddress.person').valueChanges.subscribe( () => {
      this.resetVariables();
    });

    this.formGroup.get('searchAddress.extendedValues.copyEmail').valueChanges.subscribe( value => {
      if (value) {
        this.formGroup.get('searchAddress.extendedValues.registerEmail').setValue(this.formGroup.get('searchAddress.extendedValues.email').value);
      } else {
        this.formGroup.get('searchAddress.extendedValues.registerEmail').setValue('');
      }
    });

    this.loginService.getAuthData().subscribe(userData => {
      if (userData && userData.token) {
        // User wurde eingeloggt, also redirect zum übergebenen returnPath oder /subscription
        this.navigationService.navigateTo(this.returnPath? this.returnPath : NavigationCommand.SUBSCRIPTION);
      }
    });

    this.formGroup.get('searchAddress.extendedValues.registerEmail').setAsyncValidators(CustomValidators.checkEmailAddress(
      this.http,
      this.translationService,
    ));

    this.configService.loadConfig('identification.matchCount').subscribe(data => {
      if (data && data.value && data.value !== '') {
        this.maskedOverviewText = translationService.getTranslation('identification_masked_overview');
        if (this.maskedOverviewText.includes('{count')) {
          this.maskedOverviewText = this.maskedOverviewText.replace('{count}', data.value);
        }
      }
    })
  }

  public loadAddress(): void {
    this.isLoading = true;
    this.searched = true;
    const identificationData: IdentificationData = this.buildIdentificationModelOfFormGroup();
    this.resetVariables();
    this.identificationService.checkUserAndGetMaskedExtendedData(identificationData).subscribe(result => {
      this.isLoading = false;
      if (!result) {
        this.notFoundAddress = true;
      } else {
        // Der User wurde gefunden und es sind extendedDatas vorhanden!
        if (result.maskedExtendedData && result.maskedExtendedData.size > 0) {
          this.identificationData = result;
          // Felder in der UI anzeigen und vollständig vom User abfragen.
          result.maskedExtendedData.forEach((value, key) => {
            switch (key) {
              case 'EMAIL':
                this.showEmail = true;
                break;
              case 'PHONE':
                this.showPhone = true;
                break;
              case 'IBAN':
                this.showIBAN = true;
                break;
              case 'BIRTHDAY':
                this.showBirthday = true;
                break;
              case 'CLIENTNO':
                this.showClientNo = true;
                break;
            }
          });
        } else {
          this.notFoundExtendedData = true;
        }
      }
    }, error => {
      this.isLoading = false;
    });
  }

  public checkMaskedData(): void {
    this.isLoading = true;
    const identificationData: IdentificationData = this.buildIdentificationModelOfFormGroup();
    this.identificationService.checkUserAndValidateExtendedData(identificationData).subscribe(result => {
      this.isLoading = false;
      if (result) {
        this.identificationData = result;
        if (this.showEmail && !result.matchEmail && this.formGroup.get('searchAddress.extendedValues.email').touched) {
          // Formular auf Fehlerhaft setzen!
          this.formGroup.get('searchAddress.extendedValues.email').updateValueAndValidity();
        } else if (this.showEmail && result.matchEmail) {
          this.formGroup.get('searchAddress.extendedValues.registerEmail').setValue(this.formGroup.get('searchAddress.extendedValues.email').value);
        }
        if (this.showBirthday && !result.matchBirthday && this.formGroup.get('searchAddress.extendedValues.birthday').touched) {
          // Formular auf Fehlerhaft setzen!
          this.formGroup.get('searchAddress.extendedValues.birthday').updateValueAndValidity();
        }
        if (this.showIBAN && !result.matchIBAN && this.formGroup.get('searchAddress.extendedValues.iban').touched) {
          // Formular auf Fehlerhaft setzen!
          this.formGroup.get('searchAddress.extendedValues.iban').updateValueAndValidity();
        }
        if (this.showPhone && !result.matchPhone && this.formGroup.get('searchAddress.extendedValues.phone').touched) {
          // Formular auf Fehlerhaft setzen!
          this.formGroup.get('searchAddress.extendedValues.phone').updateValueAndValidity();
        }
        if (this.showClientNo && !result.matchClientNo && this.formGroup.get('searchAddress.extendedValues.clientNo').touched) {
          // Formular auf Fehlerhaft setzen!
          this.formGroup.get('searchAddress.extendedValues.clientNo').updateValueAndValidity();
        }
      }
    }, error => {
      this.isLoading = false;
    });
  }

  public continueWithoutRegistration(): void {
    this.isLoading = true;
    if(this.identificationData?.user && this.returnPath?.length > 0) {
      this.returnPath = this.returnPath + '-without-login' as NavigationCommand;
      this.navigationService.navigateTo(this.returnPath, [this.identificationData.user.userBackendId.toString()]);
    }
  }

  public createAccount(): void {
    this.isLoading = true;
    const identificationData: IdentificationData = this.buildIdentificationModelOfFormGroup();
    this.identificationService.createAccount(identificationData).subscribe(result => {
      if (result && result.user && result.otlc && result.otlc !== '') {
        // alles ist gut, der User wurde zurückgegeben
        // Dann müssen wir den User nun mit dem OTLC einloggen
        this.loginService.loginByOtlc(result.otlc);
      } else {
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }

  public goToLogin(): void {
    this.navigationService.navigateTo(NavigationCommand.LOGIN);
  }

  private resetVariables(): void {
    this.showEmail = false;
    this.showPhone = false;
    this.showIBAN = false;
    this.showBirthday = false;
    this.showClientNo = false;
    this.identificationData = null;
    this.notFoundAddress = false;
    this.notFoundExtendedData = false;
  }

  private buildIdentificationModelOfFormGroup(): IdentificationData {
    const person = new Person(null ,null,
      this.formGroup.get('searchAddress.person.firstName').value,
      this.formGroup.get('searchAddress.person.lastName').value,
      null, null);
    const address = new Address(
      this.formGroup.get('searchAddress.address.street').value,
      this.formGroup.get('searchAddress.address.houseNo').value,
      this.formGroup.get('searchAddress.address.zipcode').value,
      this.formGroup.get('searchAddress.address.city').value,
      this.formGroup.get('searchAddress.address.country').value?.key,
      null, null, null, null, null, null, null);
    const extendedData = new Map<string, string>();
    if (this.formGroup.get('searchAddress.extendedValues.email').value) {
      extendedData.set('EMAIL', this.formGroup.get('searchAddress.extendedValues.email').value);
    }
    if (this.formGroup.get('searchAddress.extendedValues.iban').value) {
      extendedData.set('IBAN', this.formGroup.get('searchAddress.extendedValues.iban').value);
    }
    if (this.formGroup.get('searchAddress.extendedValues.birthday').value) {
      extendedData.set('BIRTHDAY', this.formGroup.get('searchAddress.extendedValues.birthday').value);
    }
    if (this.formGroup.get('searchAddress.extendedValues.phone').value) {
      extendedData.set('PHONE', this.formGroup.get('searchAddress.extendedValues.phone').value);
    }
    if (this.formGroup.get('searchAddress.extendedValues.clientNo').value) {
      extendedData.set('CLIENTNO', this.formGroup.get('searchAddress.extendedValues.clientNo').value);
    }
    return {
      person,
      address,
      extendedData,
      maskedExtendedData: this.identificationData?.maskedExtendedData ?? new Map(),
      matchIBAN: false,
      matchPhone: false,
      matchBirthday: false,
      matchEmail: false,
      matchClientNo: false,
      user: this.identificationData?.user ?? null,
      otlc: null,
      registerEmail: this.formGroup.get('searchAddress.extendedValues.registerEmail').value,
      enoughMatches: false};
  }
}

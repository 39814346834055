import {Component} from '@angular/core';
import {TranslationService} from "../../core/services/translation.service";
import {CssPropertiesEnum} from "../../core/models/css-properties.enum";
import {VHostService} from "../../core/services/v-host.service";
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent {

  CssPropertiesEnum = CssPropertiesEnum;
  constructor(
    public translationService: TranslationService,
    public vHostService: VHostService,
    public navigationService: NavigationService,

  ) {
  }

  navigateToInvoices() {
    const pageSize = document.getElementById('customer-page')?.offsetWidth < 1280 ? 1 : 2
    this.navigationService.navigateTo(NavigationCommand.CUSTOMERINVOICE, [pageSize.toString()])
  }
}

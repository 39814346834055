import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Move} from '../models/move.model';
import {BaseDataService} from '../../core/services/base-data.service';
import {ValidDate} from "../models/valid-date.model";

@Injectable({providedIn: 'root'})
export class MoveService {
  constructor(private http: HttpClient,
              private baseDataService: BaseDataService) {}

  private moves: Move[] = [];
  private loadedAll = false;

  getMoves(): Observable<Move[]>{
    if (this.loadedAll) {
      return of(this.moves);
    }
    else {
      const salt = new Date().getTime();
      return this.http.get<Move[]>('/webabo/moves?' + salt).pipe( map((loadedMoves) => {
        this.loadedAll = true;
        loadedMoves.forEach(move => {
          this.handleMove(move);
        });
        return this.moves;
      }));
    }
  }

  checkMove(data): Observable<any> {
    return this.http.post('/webabo/moves/prices', data);
  }

  sendMove(data): Observable<any> {
    return this.http.post('/webabo/moves', data).pipe(map( (move: any) => {
      this.loadedAll = false;
      this.resetData();
      return move;
    }));
  }

  resetData(): void {
    this.loadedAll = false;
    this.moves = [];
  }

  getUser(backendId): Observable<any> {
    const userData:any = {};
    userData.userBackendId = backendId;
    return this.http.get('/webabo/users/unauthorized/' + backendId).pipe(map( (user: any) => {
      return user;
    }));
  }

  handleMove(move): void {
    if ( move.webId > 0) {
      const startDate = ValidDate.cutTimeOfDate(new Date(move.validDate.validFrom));
      const nowDate = ValidDate.cutTimeOfDate(new Date());
      if (startDate && startDate.getDate() >= nowDate.getDate()) {
        this.baseDataService.handleAddress(move.address);
        this.baseDataService.handlePerson(move.person);
        this.moves.push(move);
      }
    }
  }

}


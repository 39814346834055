<ng-container *ngIf="isBuckaroo && transaction && transaction.transactionId">
  <div id="buckarooContainer" class="mt-5 text-center">
    <h5 [innerHTML]="translationService.getTranslation('buckaroo.transaction.created')"></h5>
    <div (click)="goToBuckaroo()" id="idealButton">
      <img alt="" src="assets/images/IDEAL_logo.svg">
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isPaypal">
  <ngx-paypal [config]="payPalConfig"></ngx-paypal>
</ng-container>

<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('identification_intro')"></h4>
  </div>
  <div class="col-12 mb-3" [innerHTML]="translationService.getTranslation('identification_description')">
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="isLoading" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="errorsArr.length > 0">
        <app-general-errors [errors]="errorsArr"></app-general-errors>
      </ng-container>
      <app-hup-subscription-address [controlGroup]="formGroup.get('searchAddress')" [showTitle]="false" [showSalutation]="false" [showAddressExt1]="false"></app-hup-subscription-address>
      <div class="mt-2 text-center" *ngIf="!this.formGroup.get('searchAddress').get('person').invalid && !this.formGroup.get('searchAddress').get('address').invalid">
        <button *ngIf="!identificationData" mat-raised-button color="primary" (click)="loadAddress()">{{translationService.getTranslation('identification_search_address')}}</button>
      </div>

      <ng-container *ngIf="identificationData && identificationData.maskedExtendedData && identificationData.maskedExtendedData.size > 0" [formGroup]="formGroup.get('searchAddress')">
        <div class="mt-5" formGroupName="extendedValues">
        <div class="mb-2" [innerHTML]="maskedOverviewText"></div>
        <div class="row mt-2" *ngIf="showBirthday">
          <div class="col-12 col-md-6 text-end my-auto">{{ translationService.getTranslation('address_birthday') }} {{this.identificationData.maskedExtendedData.get('BIRTHDAY')}}</div>
          <div class="col-12 col-md-6">
            <mat-form-field >
              <mat-label [innerHTML]="translationService.getTranslation('address_birthday')"></mat-label>
              <input matInput formControlName="birthday">
              <mat-error>
                {{ translationService.getTranslation('identification_wrong_birthday') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2" *ngIf="showIBAN">
          <div class="col-12 col-md-6 text-end my-auto">{{ translationService.getTranslation('payment_iban') }} {{this.identificationData.maskedExtendedData.get('IBAN')}}</div>
          <div class="col-12 col-md-6">
            <mat-form-field >
              <mat-label [innerHTML]="translationService.getTranslation('payment_iban')"></mat-label>
              <input matInput formControlName="iban">
              <mat-error>
                {{ translationService.getTranslation('identification_wrong_iban') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2" *ngIf="showPhone">
          <div class="col-12 col-md-6 text-end my-auto">{{ translationService.getTranslation('address_telephone') }} {{this.identificationData.maskedExtendedData.get('PHONE')}}</div>
          <div class="col-12 col-md-6">
            <mat-form-field >
              <mat-label [innerHTML]="translationService.getTranslation('address_telephone')"></mat-label>
              <input matInput formControlName="phone">
              <mat-error>
                {{ translationService.getTranslation('identification_wrong_phone') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2" *ngIf="showEmail">
          <div class="col-12 col-md-6 text-end my-auto">{{ translationService.getTranslation('address_email') }} {{this.identificationData.maskedExtendedData.get('EMAIL')}}</div>
          <div class="col-12 col-md-6">
            <mat-form-field >
              <mat-label [innerHTML]="translationService.getTranslation('address_email')"></mat-label>
              <input matInput formControlName="email">
              <mat-error>
                {{ translationService.getTranslation('identification_wrong_email') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2" *ngIf="showClientNo">
          <div class="col-12 col-md-6 text-end my-auto">{{ translationService.getTranslation('label_backendid') }} {{this.identificationData.maskedExtendedData.get('CLIENTNO')}}</div>
          <div class="col-12 col-md-6">
            <mat-form-field >
              <mat-label [innerHTML]="translationService.getTranslation('label_backendid')"></mat-label>
              <input matInput formControlName="clientNo">
              <mat-error>
                {{ translationService.getTranslation('identification_wrong_clientNo') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="mt-2 text-center">
          <button *ngIf="!identificationData.user" mat-raised-button color="primary" (click)="checkMaskedData()">{{ translationService.getTranslation('identification_check_extended_values') }}</button>
        </div>

          <div class="row mt-5" *ngIf="identificationData.user">
            <div class="row mb-3 justify-content-between" *ngIf="returnPath && !registeringAgreed">
              <button mat-flat-button
                      class="btn btn-secondary col-5"
                      (click)="continueWithoutRegistration()"
                      [innerHTML]="translationService.getTranslation('identification_continueWithoutRegistration')">
              </button>
              <button mat-raised-button
                      class="btn col-5" color="primary"
                      (click)="registeringAgreed = true;"
                      [innerHTML]="translationService.getTranslation('identification_continueWithRegistration')">
              </button>
            </div>
            <ng-container *ngIf="registeringAgreed || !returnPath">
              <div class="mb-2" [innerHTML]="translationService.getTranslation('identification_registration_email_overview')"></div>
              <ng-container *ngIf="!identificationData.matchEmail">
                <div class="col-12 col-md-6 offset-md-6" *ngIf="showEmail && formGroup.get('searchAddress.extendedValues.email').value !== ''">
                  <mat-checkbox formControlName="copyEmail"><div [innerHTML]="translationService.getTranslation('identification_registration_same_email')"></div></mat-checkbox>
                </div>
              </ng-container>
              <div class="col-12 col-md-6 text-end my-auto">{{ translationService.getTranslation('address_email') }}</div>
              <div class="col-12 col-md-6">
                <mat-form-field>
                  <mat-label [innerHTML]="translationService.getTranslation('address_email')"></mat-label>
                  <input matInput formControlName="registerEmail" [readonly]="identificationData.matchEmail">
                  <mat-spinner class="iconInInput" *ngIf="formGroup.get('searchAddress.extendedValues.registerEmail').pending"
                               matSuffix [diameter]="18"
                               style="float: right; margin-left: 18px"></mat-spinner>
                  <mat-icon class="iconInInput" *ngIf="formGroup.get('searchAddress.extendedValues.registerEmail').valid" matSuffix>
                    check
                  </mat-icon>
                  <mat-error *ngIf="formGroup.get('searchAddress.extendedValues.registerEmail').hasError('message')">
                    {{ formGroup.get('searchAddress.extendedValues.registerEmail').getError('message') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="mt-2" *ngIf="formGroup.get('searchAddress.extendedValues.registerEmail').valid">
                <div [innerHTML]="translationService.getTranslation('identification_register_description')"></div>
                <div class="mt-2 text-center">
                  <button mat-raised-button color="primary" (click)="createAccount()">{{ translationService.getTranslation('identification_create_account') }}</button>
                </div>
              </div>

              <div class="mt-2" *ngIf="formGroup.get('searchAddress.extendedValues.registerEmail').hasError('message')">
                <div [innerHTML]="translationService.getTranslation('identification_go_to_login_description')"></div>
                <div class="mt-2 text-center">
                  <button mat-raised-button color="primary" (click)="goToLogin()">{{ translationService.getTranslation('identification_go_to_login') }}</button>
                </div>
              </div>
            </ng-container>
          </div>
      </div>
      </ng-container>

      <div *ngIf="searched && notFoundAddress" class="mt-5" [innerHTML]="translationService.getTranslation('identification_notFoundUser')"></div>
      <div *ngIf="searched && notFoundExtendedData" class="mt-5" [innerHTML]="translationService.getTranslation('identification_notFoundExtendedData')"></div>
    </ng-container>
  </div>
</div>

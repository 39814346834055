<ng-container *ngIf="filteredLinks && filteredLinks.length > 0">
<nav class="navbar navbar-expand-lg navbar-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="WebAboNav" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div id="WebAboNav" class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
    <ul [className]="horizontalNavigation && isCollapsed ? 'navbar-nav flex-row' : 'navbar-nav flex-column'">
      <ng-container *ngFor="let link of filteredLinks">
        <ng-container *ngIf="link.showInNavigation">
          <li class="nav-item" *ngIf="link.link !== 'login';else loginNavi">
            <a *ngIf="link.isExtern; else internLink" class="nav-link" (click)="navigateToExternRoute(link)">{{link.description}}</a>
            <ng-template #internLink>
              <a class="nav-link" [routerLink]="link.link" routerLinkActive="active" data-cy="navLink">{{link.description}}</a>
            </ng-template>
          </li>
          <ng-template #loginNavi>
            <li class="nav-item">
              <a class="nav-link" (click)="onClickEvent()">
                <app-login #login></app-login>
              </a>
            </li>
          </ng-template>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</nav>
</ng-container>

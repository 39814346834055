import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from "./login.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ChildUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.loginService.isChildUser$.pipe(
      map(childUserState => {
        if (childUserState === null) {
          // state unknown >> force request
          this.loginService.checkStatus();
          return true;
        } else if (!childUserState) {
          return true;
        } else {
          this.router.navigateByUrl('/child-user-info', {skipLocationChange: true});
          return false;
        }
      })
    );
  }
}

<div class="offerView" *ngIf="actualPage">
  <div class="mb-5">
    <img [alt]="actualPage.description" [src]="actualPage.viewImageUrl">
  </div>
  <div class="mb-5">
    <div class="offerViewDescription" [innerHtml]="actualPage.viewDescription"></div>
  </div>
</div>

<div class="row" [className]="cssPropertiesEnum.offerHeaderClasses|cssProperty: vHostService" id="offerGroupHeader">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('offer_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('offer_description')">
  </div>
</div>
<div class="row mb-3">
  <div class="col-12" id="offerGroupErrors">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div *ngIf="isLoadingOfferGroup" style="text-align: center;" id="offerGroupSpinner">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div>

<app-image-slider *ngIf="externalOffersForSlider.length >= 1" [externalOffers]="externalOffersForSlider"></app-image-slider>

<div class="offerGroupPage" id="offerGroups">
  <div class="row">
    <ng-container *ngFor="let group of offerGroups">
        <div *ngIf="group.hasOffers" [className]="
                  'col-' + (CssPropertiesEnum.offerFxFlex|cssProperty: vHostService) + ' ' +
                  'col-sm-' + (CssPropertiesEnum.offerFxFlexSM|cssProperty: vHostService) + ' ' +
                  'col-md-' + (CssPropertiesEnum.offerFxFlexMD|cssProperty: vHostService) + ' ' +
                  'col-lg-' + (CssPropertiesEnum.offerFxFlexLG|cssProperty: vHostService) + ' ' +
                  'col-xl-' + (CssPropertiesEnum.offerFxFlexXL|cssProperty: vHostService) + ' ' +
                  'col-xxl-' + (CssPropertiesEnum.offerFxFlexXXL|cssProperty: vHostService)">
        <mat-card appearance="outlined" class="mat-elevation-z4 pointer h-100">
          <div class="heading">
            {{group.description}}
          </div>
          <div class="offerImage">
          <img mat-card-image *ngIf="group.image"
               [src]="group.image" [alt]="group.value">
          </div>
          <mat-card-content class="mt-3" [innerHTML]="group.webDescription"></mat-card-content>
          <div class="footer">
            <button mat-raised-button (click)="goToOfferModule(group.rId, 1)"
                    [innerHTML]="translationService.getTranslation('button_goToOrder')" data-cy="btn-openOfferGroup"></button>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>


import {AfterContentChecked, Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[fitImage]',
})
export class FitImageDirective implements AfterContentChecked {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterContentChecked(): void {
    this.doResize();
  }

  doResize(): void {
    const naturalHeight = this.el.nativeElement.naturalHeight;
    const naturalWidth = this.el.nativeElement.naturalWidth;

    if (naturalHeight > naturalWidth && naturalHeight > 0 && naturalWidth > 0) {
      const ratio = naturalWidth / naturalHeight;
      const remaining = 1 - ratio;

      this.renderer.setStyle(this.el.nativeElement, 'width', 'auto');
      this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
      //this.renderer.setStyle(this.el.nativeElement, 'margin-left', (remaining * 100 / 2) + '%');
    }
    else if (naturalWidth > naturalHeight && naturalHeight > 0 && naturalWidth > 0) {
      const ratio = naturalHeight / naturalWidth;
      const remaining = 1 - ratio;

      this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
      //this.renderer.setStyle(this.el.nativeElement, 'padding-top', (remaining * 100 / 2) + '%');
    }
    else {
      this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
      this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
    }
  }
}

import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {EMPTY, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Person} from '../../data/models/person.model';
import {Address} from '../../data/models/address.model';
import {Globals} from '../globals';
import {BaseData} from '../../data/models/base-data.model';
import {ComplaintCode} from "../../data/models/complaint-code.model";
import {ClientType} from "../../data/models/client-type.model";
import {AddressCode} from "../../data/models/address-code.model";

@Injectable({providedIn: 'root'})
export class BaseDataService {
  baseData = [];

  constructor(private http: HttpClient) {
  }

  getBaseData<T extends BaseData>(type: string): T[] {
    return this.baseData[type];
  }

  loadBaseData<T extends BaseData>(type: string): Observable<T[]> {
    // ggf. unteren request abspeichern und evtl. den zurückgeben?
    if (this.baseData[type]) {
      return of(this.baseData[type]);
    }

    const baseData = JSON.parse(localStorage.getItem('baseData_' + type));
    if (baseData && new Date(baseData.expirationDate) > new Date()) {
      if (Array.isArray(baseData.data)) {
        this.baseData[type] = baseData.data;
      } else {
        this.baseData[type] = [baseData.data];
      }

      return of(this.baseData[type]);
    }

    return this.http.get<T[]>('/webabo/basedata/' + type).pipe(
      map(data => {
        this.baseData[type] = data;
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + Globals.CACHE_TIME_BASE_DATA);
        localStorage.setItem('baseData_' + type, JSON.stringify({expirationDate, data}));
        return this.baseData[type];
      }),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }));
  }

  loadComplaintCodes(url: string): Observable<ComplaintCode[]> {
    return this.http.get<ComplaintCode[]>('/webabo/basedata/' + url).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }));
  }

  handleAddress(address: Address): void {
    if (!address) {
      return;
    }

    this.loadBaseData('countrycodes').subscribe(countries => {
      countries.forEach(country => {
        if (country.key === address.country) {
          address.countryDescription = country.description;
        }
      });
    });

    // TODO: HouseNoVector auflösen
  }

  handlePerson(person: Person): void {
    if (!person) {
      return;
    }

    this.loadBaseData('titles').subscribe(titles => {
      titles.forEach(title => {
        if (title.key === person.title) {
          person.titleDescription = title.description;
        }
      });
    });

    if (!person.titleDescription) {
      person.titleDescription = '';
    }

    this.loadBaseData('clienttypes').subscribe(clientTypes => {
      clientTypes.forEach(clientType => {
        if(clientType.key === person.salutation) {
          person.salutationDescription = clientType.description;
          if(!person.addressCode) {
            person.addressCode = (clientType as ClientType).defaultAddressCodeId;
          }
        }
      });
    });
    this.loadBaseData('addresscodes').subscribe(addressCodes  => {
      addressCodes.forEach(addressCode => {
        if (addressCode.key === person.addressCode) {
          person.addressCodeDescription = addressCode.description;
          if(!person.salutation) {
            person.salutation = (addressCode as AddressCode).defaultClientTypeKey;
          }
        }
      });
    });
  }

  handleComplaint(complaint): void {
    if (!complaint) {
      return;
    }

    this.loadBaseData('complaintgroupcodes/' + complaint.reasongroup + '/complaintreasons').subscribe(reasons => {
      reasons.forEach(reason => {
        if (reason.key === complaint.reason || reason.key === complaint.type) {
          complaint.reasonBaseData = reason;
        }
      });
    });
  }

  handleUnauthorizedOrder(sub): void {
    if (!sub) {
      return;
    }

    this.addVariantToSubscription(sub).then(r => {
      this.addProductCodeToSubscription(sub).then();
    });
  }

  private async addProductCodeToSubscription(sub) {
    this.loadBaseData('products').subscribe(products => {
      products.forEach(product => {
        if (product.key === sub.productCode) {
          sub.product = product;
          return Promise.resolve();
        }
      });
    });
  }

  private async addVariantToSubscription(sub) {
    this.loadBaseData('variantcode/' + sub.variantCode).subscribe(variantCode => {
      sub.variant = variantCode;
      return Promise.resolve();
    });
  }

  public getVariantCode(variantCode: string): Observable<BaseData> {
    return this.http.get<BaseData>('/webabo/basedata/variantcode/' + variantCode).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }));
  }
}

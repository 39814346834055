import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {OfferComponent} from './webabo/offer/offer.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotFoundComponent} from './core/components/not-found/not-found.component';
import {OfferDetailComponent} from './webabo/offer/offer-detail/offer-detail.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {FitImageDirective} from './core/directives/fit-image.directive';
import {BonusDetailComponent} from './webabo/offer/offer-detail/bonus-detail/bonus-detail.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ClickStopPropagationDirective} from './core/directives/click-stop-propagation.directive';
import {MatDividerModule} from '@angular/material/divider';
import {LoginComponent} from './core/components/login/login.component';
import {NavigationComponent} from './webabo/navigation/navigation.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {LoginBoxComponent} from './core/components/login/login-box/login-box.component';
import {HupSubscriptionComponent} from './webabo/hup-subscription/hup-subscription.component';
import {ComplaintComponent} from './webabo/complaint/complaint.component';
import {MoveComponent} from './webabo/move/move.component';
import {HupSubscriptionDetailComponent} from './webabo/hup-subscription/hup-subscription-detail/hup-subscription-detail.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {LoginInterceptorService} from './core/components/login/login-interceptor.service';
import {LoadingSpinnerComponent} from './core/components/loading-spinner/loading-spinner.component';
import {ParentComponent} from './core/components/parent/parent.component';
import {AddressDetailComponent} from './core/components/address-detail/address-detail.component';
import {ContactComponent} from './webabo/contact/contact.component';
import {MatCardModule} from '@angular/material/card';
import {SubChangeComponent} from './webabo/sub-change/sub-change.component';
import {ComplaintEditComponent} from './webabo/complaint/complaint-edit/complaint-edit.component';
import {SubChangeEditComponent} from './webabo/sub-change/sub-change-edit/sub-change-edit.component';
import {MatIconModule} from '@angular/material/icon';
import {GeneralErrorsComponent} from './core/components/general-errors/general-errors.component';
import {PaymentComponent} from './webabo/payment/payment.component';
import {PaymentEditComponent} from './webabo/payment/payment-edit/payment-edit.component';
import {SubscriptionPaymentEditComponent} from './webabo/payment/subscription-payment-edit/subscription-payment-edit.component';
import {LandingPageComponent} from './webabo/landing-page/landing-page.component';
import {BreadcrumbComponent} from './core/components/breadcrumb/breadcrumb.component';
import {
  HupSubscriptionAddressComponent
} from './webabo/hup-subscription/hup-subscription-detail/hup-subscription-address/hup-subscription-address.component';
import {ComplaintWihtoutLoginComponent} from './webabo/complaint/complaint-wihtout-login/complaint-wihtout-login.component';
import {DatePipe, NgOptimizedImage, registerLocaleData} from '@angular/common';
import {OfferOrderedOverviewComponent} from './webabo/offer/offer-ordered-overview/offer-ordered-overview.component';
import {HupSubscriptionTerminateComponent} from './webabo/hup-subscription/hup-subscription-terminate/hup-subscription-terminate.component';
import {RedirectComponent} from './core/components/redirect/redirect.component';
import {LoginFormularComponent} from './core/components/login-formular/login-formular.component';
import {CookieModule} from 'ngx-cookie';
import {OnlinepaymentComponent} from './core/components/onlinepayment/onlinepayment.component';
import {PaymentRedirectComponent} from './core/components/onlinepayment/payment-redirect/payment-redirect.component';
import {ContactWithoutLoginComponent} from './webabo/contact/contact-without-login/contact-without-login.component';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faHourglass, faHourglassStart} from '@fortawesome/free-solid-svg-icons';
import {NgbCarouselModule, NgbCollapse, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {VHostService} from './core/services/v-host.service';
import {CssPropertyPipe} from './core/pipes/css-property.pipe';
import {OfferGroupComponent} from './webabo/offer-group/offer-group.component';
import {InArrayPipe} from './core/pipes/in-array.pipe';
import {OfferCardComponent} from './webabo/offer/offer-card/offer-card.component';
import {NgxPayPalModule} from 'ngx-paypal';
import localeGermany from '@angular/common/locales/de';
import localeNederland from '@angular/common/locales/nl';
import localeEnglish from '@angular/common/locales/en';
import {TranslateModule} from '@ngx-translate/core';
import {LocaleProvider} from './core/services/locale.provider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {ConfigService} from './core/services/config.service';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {ClientSearchComponent} from './webabo/client-search/client-search.component';
import {HouseNoPipe} from './core/pipes/houseno.pipe';
import {BankAccountPipe} from './core/pipes/bank-account.pipe';
import {
  HupSubscriptionAddressSpecificsComponent
} from './webabo/hup-subscription/hup-subscription-detail/hup-subscription-address/hup-subscription-address-specifics/hup-subscription-address-specifics.component';
import {ContactOverviewComponent} from './webabo/contact/contact-overview/contact-overview.component';
import {MatVerticalStepperScrollerDirective} from './core/directives/mat-vertical-stepper-scroller.directive';
import {DelayComponent} from './webabo/delay/delay.component';
import {ImageSliderComponent} from './webabo/image-slider/image-slider.component';
import {ExternalOfferService} from './core/services/external-offer.service';
import {MatRadioModule} from '@angular/material/radio';
import {ExternalOfferCardComponent} from './webabo/offer/external-offer-card/external-offer-card.component';
import {faEuro} from '@fortawesome/free-solid-svg-icons/faEuro';
import {ImageUploaderComponent} from "./image-uploader/image-uploader.component";
import {ImageListElementComponent} from "./image-list-element/image-list-element.component";
import {ComplaintWithoutLoginAfterComponent} from './webabo/complaint/complaint-without-login-after/complaint-without-login-after.component';
import {IdentificationComponent} from "./webabo/identification/identification.component";
import {GeneralWarningComponent} from "./general-warning/general-warning.component";
import { SubChangeWithoutLoginComponent } from './webabo/sub-change/sub-change-without-login/sub-change-without-login.component';
import { CustomerComponent } from './webabo/customer/customer.component';
import { CustomerInvoiceComponent } from './webabo/customer/customer-invoice/customer-invoice.component';
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {SafeResourcePipe} from "./core/pipes/safe-resource.pipe";
import {CustomPaginatorIntl} from "./core/services/custom-paginator-intl.service";
import { ChildUserInformationComponent } from './core/components/child-user-information/child-user-information.component';
import {
  ProductVariantPeriodBlockComponent
} from "./webabo/hup-subscription/hup-subscription-detail/product-variant-period-block/product-variant-period-block.component";
import {FaqGroupComponent} from "./webabo/faqgroup/faqgroup.component";
import {FaqComponent} from "./webabo/faq/faq.component";
import {
  SubChangeWithoutLoginAfterComponent
} from "./webabo/sub-change/sub-change-without-login-after/sub-change-without-login-after.component";
import {DatePickerFormatDirective} from "./core/directives/date-picker-format.directive";
import {MatMomentDateModule} from "@angular/material-moment-adapter";

registerLocaleData(localeGermany);
registerLocaleData(localeNederland);
registerLocaleData(localeEnglish);
export function vHostFactory( service: VHostService) {
  return () => service.loadVhosts();
}

export function initLocale(configService: ConfigService) {
  return () => configService.initLocale();
}

export function initDefaultCountry(configService: ConfigService) {
  return () => configService.initDefaultCountry();
}

@NgModule({
  declarations: [
    AppComponent,
    OfferGroupComponent,
    OfferComponent,
    NotFoundComponent,
    OfferDetailComponent,
    BonusDetailComponent,
    FitImageDirective,
    DatePickerFormatDirective,
    MatVerticalStepperScrollerDirective,
    ClickStopPropagationDirective,
    LoginComponent,
    NavigationComponent,
    LoginBoxComponent,
    HupSubscriptionComponent,
    HupSubscriptionAddressSpecificsComponent,
    ComplaintComponent,
    MoveComponent,
    HupSubscriptionDetailComponent,
    LoadingSpinnerComponent,
    ParentComponent,
    AddressDetailComponent,
    ContactComponent,
    SubChangeComponent,
    ComplaintEditComponent,
    SubChangeEditComponent,
    GeneralErrorsComponent,
    GeneralWarningComponent,
    PaymentComponent,
    PaymentEditComponent,
    SubscriptionPaymentEditComponent,
    LandingPageComponent,
    BreadcrumbComponent,
    HupSubscriptionAddressComponent,
    HupSubscriptionAddressSpecificsComponent,
    ComplaintWihtoutLoginComponent,
    OfferOrderedOverviewComponent,
    HupSubscriptionTerminateComponent,
    RedirectComponent,
    LoginFormularComponent,
    OnlinepaymentComponent,
    PaymentRedirectComponent,
    ContactWithoutLoginComponent,
    CssPropertyPipe,
    InArrayPipe,
    HouseNoPipe,
    OfferCardComponent,
    ClientSearchComponent,
    HouseNoPipe,
    BankAccountPipe,
    ContactOverviewComponent,
    DelayComponent,
    ImageSliderComponent,
    ExternalOfferCardComponent,
    ImageUploaderComponent,
    ImageListElementComponent,
    ExternalOfferCardComponent,
    IdentificationComponent,
    SubChangeWithoutLoginComponent,
    CustomerComponent,
    CustomerInvoiceComponent,
    SafeResourcePipe,
    ChildUserInformationComponent,
    ProductVariantPeriodBlockComponent,
    FaqComponent,
    FaqGroupComponent
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserTransferStateModule,
        TransferHttpCacheModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatStepperModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatCheckboxModule,
        MatGridListModule,
        MatDialogModule,
        MatDividerModule,
        MatRadioModule,
        MatToolbarModule,
        MatExpansionModule,
        MatCardModule,
        MatIconModule,
        CookieModule.forRoot(),
        FontAwesomeModule,
        NgbTooltipModule,
        MatAutocompleteModule,
        NgxPayPalModule,
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        NgxMaskDirective,
        NgbCollapse,
        NgbCarouselModule,
        ComplaintWithoutLoginAfterComponent,
        SubChangeWithoutLoginAfterComponent,
        NgOptimizedImage,
        MatPaginatorModule,
        MatMomentDateModule
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
    LocaleProvider,
    {provide: HTTP_INTERCEPTORS, useClass: LoginInterceptorService, multi: true},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    DatePipe,
    VHostService,
    { provide: APP_INITIALIZER, useFactory: vHostFactory, deps: [VHostService], multi: true},
    { provide: APP_INITIALIZER, useFactory: initLocale, deps: [ConfigService], multi: true},
    { provide: APP_INITIALIZER, useFactory: initDefaultCountry, deps: [ConfigService], multi: true},
    provideNgxMask(),
    ExternalOfferService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl},
    ],
  entryComponents: [
    BonusDetailComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(fawsome: FaIconLibrary) {
    fawsome.addIcons(
      faHourglassStart,
      faEuro,
      faHourglass
    )
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'app-parent',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent {

}

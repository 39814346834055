import {Component, Input} from '@angular/core';
import {NavigationService} from 'src/app/data/services/navigation.service';
import {ExternalOffer} from '../../data/models/external-offer.model';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']

})
export class ImageSliderComponent {
  @Input() externalOffers: ExternalOffer[] = [];
  public showOfferData: boolean = false;
  public configNameShowOfferData = 'slider_show_external_offer_data';
  public configKeyTextAlign: string = 'slider_external_offer_data_position';
  public configKeyTextColor: string = 'slider_external_offer_data_color';
  public configKeyTextFontSize: string = 'slider_external_offer_data_size';
  public textAlignValue: string = 'center';
  public textColorValue: string = '#FFF';
  public textFontSize: string = '20';
  public textFontFamily: string = 'Roboto';
  private errorMessage = 'Error while loading config for ';

  constructor(public navigationService: NavigationService) {
    this.externalOffers = this.externalOffers.filter(offer => offer.imageUrl != null && offer.hasImage);
  }
}

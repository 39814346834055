import {SafeUrl} from "@angular/platform-browser";

export enum BonusType {
  MONEY_BONUS = 'MONEY_BONUS',
  THIRD_PARTY_MONEY_BONUS = 'THIRD_PARTY_MONEY_BONUS',
  NON_MONEY_BONUS = 'NON_MONEY_BONUS',
  THIRD_PARTY_NON_MONEY_BONUS = 'THIRD_PARTY_NON_MONEY_BONUS'
}
export class Bonus {
  constructor(
    public articleCode: string,
    public name: string,
    public description: string,
    public descriptionFromDocument: string,
    public priceAmount: number,
    public portoAmount: number,
    public userAdjustment: number,
    public availableFlag: boolean,
    public bonusValue: number,
    public hasImage: boolean,
    public img: SafeUrl,
    public isoCurrencyCode: string,
    public quantity: number,
    public bonusType: BonusType,
    public selected: boolean = false

    /*
        articleTaxId: number,
        available: boolean,
        description: string,
        id: string,
        sortKey: number,
        supplierId: string,
        taxIncluded: boolean,
        validDate: {
          validFrom: Date,
          validUntil: Date
        }
    */
  ) {
  }
}
